@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700&display=swap');

:root {
    --main: #76B947;
    --secondary: #94C973;
    --dark: #2F5233;
    --light: #B1D8B7;
    --yellow: #FEC10E;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
}

body {
    font-family: 'Montserrat', sans-serif !important;
    overflow-x: hidden;
    line-height: 2;
}

a {
    color: #333 !important;
    text-decoration: none !important;
}

ul {
    list-style: none !important;
}

/* BUTTON  */
.btn {
    border-radius: 20px !important;
    color: var(--light) !important;
    transition: .4s ease-in-out !important;

}

.main-btn {
    padding: .7rem 1rem !important;
    border: 1px solid var(--main) !important;
}

.main-btn:hover {
    background-color: var(--dark) !important;
    color: yellow !important;
}

.btn-submit {
    padding: .8rem 1.3rem !important;
    color: white !important;
    background: var(--secondary) !important;
    font-size: 1.2rem !important;
}

.btn-submit:hover {
    background-color: var(--yellow) !important;
    color: var(--dark) !important;
}

.btn-contact {
    border: 1px solid var(--yellow) !important;
    color: var(--yellow) !important;
    padding: .8rem .6rem !important;
}

.btn-contact:hover {
    background-color: var(--dark) !important;
    color: var(--light) !important;
}

.btn-shop {
    width: 50% !important;
    padding: .7rem .9rem !important;
    color: var(--dark) !important;
    border: 2px solid var(--dark) !important;
    text-align: center !important;
    margin: 1rem !important;
    font-size: 1rem !important;
}

.btn-shop:hover {
    background-color: var(--secondary) !important;
}

.detail {
    padding: .6rem 1rem !important;
    color: var(--dark) !important;
    border: 2px solid var(--dark) !important;
}

.detail:hover {
    color: var(--light) !important;
    background-color: var(--dark) !important;
}

.sec-btn {
    color: var(--main) !important;
    padding: .9rem 1rem !important;
}

.sec-btn:hover {
    background-color: var(--secondary) !important;
    color: var(--dark) !important;
}

/* Top-Nav */

.top-nav {
    /* position: absolute !important;
    top: 0 !important;
    left: 0 !important; */
    width: 100% !important;
}

.info-bar {
    background-color: #D4B172 !important;
    color: #fff !important;
    width: 100% !important;
    height: 40px !important;
    z-index: 10 !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.info-num {
    padding: 10px !important;
    margin-left: 50px !important;
}

.info-link {
    display: block !important;
    color: white !important;
    font-size: 1rem !important;
    margin-right: 30px !important;
    border-radius: 20px !important;
    border: 1px solid white !important;
    color: white !important;
    padding: 2px 5px !important;
    transition: .4s ease-in-out !important;
}

.info-link a {
    color: white !important;
}

.info-link:hover {
    background-color: white !important;
    border-color: var(--dark) !important;
}

.info-link:hover a {
    color: var(--main) !important;
}

/* Nav Menu  */


.menu-bar {
    width: 100% !important;
}

.nav-logo {
    display: flex !important;
    justify-content: center !important;
}

.logo-img {
    width: 130px !important;
    margin: 10px 0 !important;
}

.logo {
    width: 100% !important;
    height: 100% !important;
}

.menu-list {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    box-shadow: inset 0 -3px 10px var(--light) !important;
    padding: 20px !important;
}

.menu-items {
    margin-top: 20px !important;
}

.menu-item {
    padding: 10px 30px !important;
    margin: 0 10px !important;
    font-size: 1.1rem !important;
    transition: all .3s ease !important;
    border-radius: 10px !important;
}

.menu-item:hover {
    background-color: #f4f4f4 !important;
    color: var(--dark) !important;
}

/* .menuicon  */

.menu-icon {
    display: none !important;
    padding: 2rem !important;
    cursor: pointer !important;
}

.icon {
    font-size: 2rem !important;
    color: var(--dark) !important;
    transition: .3s !important;
}

.icon:hover {
    color: var(--main) !important;
}


/* .dropdown {} */

.dropdown {
    display: inline-block;
}

.drop {
    display: block !important;
}


.dropdown-menu {
    display: none !important;
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    width: 100% !important;
    background: white !important;
    border-radius: 10px !important;
}

.dropdown:hover .dropdown-menu {
    display: block !important;
}

.dropdown-menu .menu-item {
    display: block !important;
    text-align: center !important;
    padding: 10px !important;
    border-bottom: 1px solid var(--dark);
    font-size: 1rem !important;
}

/* Footer */
.footer {
    background-color: white !important;
    padding: 20px !important;
    box-shadow: 0px 3px 6px #2F5233 !important;

}

.footer-content {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.footer-img {
    width: 150px !important;
}

.footer-img img {
    width: 100% !important;
}

.footer-copyright {
    color: var(--dark) !important;
    font-size: 1.2rem !important;
}

/* Banner */

.banner {
    width: 100% !important;
    height: 40vh !important;
}

.banner-content {
    background-image: url('./img/banner3.jpg') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.product-banner-content {
    background-image: url('./img/products_banner.png') !important;

}

.contact-banner {
    background-image: url('./img/contact-banner-image.jpg') !important;

}

.about-banner {
    background-image: url('./img/banner-2.webp') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
}

.banner-head {
    color: white !important;
    font-size: 2.5rem !important;
}

/* About  */

.about-container {
    width: 80% !important;
    margin: auto !important;
}

.about-content {
    display: grid !important;
    grid-template-columns: repeat(2, 50%) !important;
    margin: 2rem 0 !important;
}

.about-text {
    font-size: .9rem !important;
    line-height: 2 !important;
    background-color: #f4f4f4 !important;
    padding: 2rem !important;
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
}

.about-image {
    width: 100% !important;
    margin-bottom: 1rem !important;
}

.about-image img {
    width: 100% !important;
    box-shadow: 0px 0px 5px #2F5233 !important;
}

.contact-container {
    width: 80% !important;
    margin: auto !important;
}

.contact-head {
    text-align: center !important;
    font-size: 2rem !important;
    color: var(--dark) !important;
    margin: 2rem 0 !important;
}

.contact-content {
    background-color: #f4f4f4 !important;
    width: 80% !important;
    margin: auto !important;
    margin-bottom: 2rem !important;
    display: flex !important;
    justify-content: space-between !important;
}

.contact-form {
    width: 80% !important;
    margin: auto !important;
    padding: 20px !important;
}

.contact-input {
    display: block !important;
    border-radius: 20px !important;
    background: transparent !important;
    border: 2px solid var(--main) !important;
    width: 100% !important;
    padding: 15px !important;
    font-size: 1rem !important;
    color: var(--dark) !important;
    margin: 20px 0 !important;
}

/* Product */
.product-container {
    width: 80% !important;
    margin: auto !important;
}

.product-content {
    margin: 2rem !important;
}

.product-card {
    display: grid !important;
    grid-template-columns: repeat(2, 50%) !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 1rem 0 !important;
    border-bottom: 2px solid var(--main) !important;

}

.product-image {
    width: 100% !important;
}

.product-image img {
    width: 100% !important;
    box-shadow: 0px 0px 4px var(--light) !important;

}

/* .product-image .incense-sticks {
    width: 70% !important;
    height: 300px !important;
    margin: auto !important;
} */

.product-btn {
    margin: 1rem 0 !important;
}

.product-details {
    background-color: #f4f4f4 !important;
    padding: 1rem !important;
    margin: 20px !important;
    line-height: 1.6 !important;
    width: 100% !important;
}

.product-text h4 {
    font-size: 1.6rem !important;
}

.product-detail-container {
    width: 80% !important;
    margin: auto !important;
}

.detail-content {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 2rem !important;
}

.image {
    width: 40% !important;
}

.image img {
    width: 100% !important;
}

.detail-text {
    width: 50% !important;
    background-color: #f4f4f4 !important;
    line-height: 2 !important;
    padding: 1rem !important;
}

.detail-text h4 {
    font-size: 2rem !important;
    color: var(--dark) !important;
}

.detail-text span {
    font-weight: 600 !important;
    margin: 20px 0 !important;
}

.gallery {
    width: 100% !important;
    margin: auto !important;
}

.gallery-head {
    font-size: 1.7rem !important;
    margin: 1rem 0 !important;
    font-weight: 600 !important;
    text-align: center !important;
}

.gallery-items {
    display: grid !important;
    grid-template-columns: repeat(3, 33%) !important;
    grid-gap: 1rem !important;
}

.gallery-item {
    width: 100% !important;
}

.gallery-item img {
    width: 100% !important;
}


.detail-btn {
    display: block !important;
    margin: 1rem !important;
}

.modal {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 100vh !important;
}


.modal-content {
    width: 100% !important;
    height: 100% !important;
}

.modal-content img {
    width: auto !important;
    margin-top: 2rem !important;
    height: 90% !important;
    box-shadow: 0px 0px 100px rgba(51, 51, 51, 0.555) !important;
}

.close-icon {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    padding: 2rem !important;
}

.close-icon i {
    color: var(--dark) !important;
    font-size: 3rem !important;
    font-weight: 600 !important;
}

.close-none {
    display: none !important;
}

.display-none {
    display: none !important;
}

.display-block {
    display: block !important;
}

/* Header  */
.header {
    display: none;
    width: 100% !important;
    padding: 20px;
    /* height: 70vh !important; */
    background: url('./img/newslide1.jpeg')no-repeat center/cover !important;
    background-position: left !important;
}

.header-content {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    height: 100% !important;
}

/* .header-text h3 {
    color: var(--dark) !important;
} */

.slider-img {
    width: 100% !important;
    height: 70vh !important;
}

.slider-container {
    width: 100% !important;
    height: 70vh !important;
}

.slider-section {
    display: block;
}

.header-text {
    width: 50% !important;
    font-size: 1.4rem !important;
    color: transparent !important;

}

.header-text span {
    font-weight: 600 !important;
    font-size: 1.3rem !important;
}

.header-btn {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    margin: 1rem 0 0 0 !important;
}

.about-section {
    width: 80% !important;
    margin: auto !important;
    padding: 1rem !important;
}

.section-title {
    text-align: center !important;
    font-size: 2rem !important;
    font-weight: 600 !important;
    color: var(--dark) !important;
    width: 30% !important;
    margin: auto !important;
    border-bottom: 2px solid var(--dark) !important;
    padding-bottom: 10px !important;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}

.about-section-content {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;
}

.section-image {
    width: 50% !important;
}

.section-image img {
    width: 100% !important;
}

.section-content {
    width: 50% !important;
    text-align: right !important;
    background-color: #f4f4f4 !important;
    padding: 1rem !important;
    line-height: 1.6 !important;
}

.section-desc {
    line-height: 1.6rem !important;
}

.section-btn {
    margin: 1rem !important;
}

/* Categories */
.categories-section {
    background-color: #f4f4f4 !important;
    padding: 2rem 0 !important;
}

.categories-container {
    width: 100% !important;
    margin-bottom: 2rem !important;
}

.cat-section-content {
    width: 80% !important;
    margin: auto !important;
    display: grid !important;
    grid-template-columns: repeat(3, 33%) !important;
    grid-gap: 1rem !important;
}

.cat-card {
    box-shadow: 0px 0px 3px #333 !important;
    width: 100% !important;
    border-radius: 10px !important;
}

.cat-card-content {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    margin: auto !important;
}

.cat-image {
    display: flex !important;

    align-items: center !important;
    width: 100% !important;
    height: auto !important;
}

.cat-image img {
    margin: auto !important;
    width: 400px !important;
    height: 100%;
    margin-top: 20px !important;
}

.cat-image .stick {
    width: 300px !important;
}

.cat-image .soap {
    width: 100% !important;
}

.cat-image .chettinadu {
    width: 300px;
}

.cat-title {
    font-size: 1.4rem !important;
    margin-top: 1rem;
    text-align: center !important;
    color: var(--dark) !important;
}




/* Comment  */
.comment-card {
    width: 80% !important;
    margin: auto !important;
}

.comment-section-container {
    display: grid !important;
    grid-template-columns: repeat(3, 33%) !important;
}

.comment-card {
    background: #f4f4f4 !important;
    border-radius: 20px !important;
    border: 1px solid var(--dark) !important;
    padding: 1rem !important;
    height: 100% !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.comment-card-container {
    margin-top: 20px !important;

}

.comment-image {
    width: 50% !important;
    margin: auto !important;
}

.comment-image img {
    width: 100% !important;
    border-radius: 100% !important;
}

.comment-title {
    text-align: center !important;
    font-weight: 600 !important;
    font-size: 1.3rem !important;
}

.comment-desc {
    font-size: .9rem !important;
}

.comment-name {
    font-weight: 600 !important;
}

.contact-section-container {
    width: 100% !important;
    background: url('./img/contact-banner-image.jpg') no-repeat center center/cover !important;
    padding: 3rem !important;
    box-shadow: inset 100px 0px 100px rgba(0, 0, 0, 0.651) !important;

}

.contact-content {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    background: none !important;
}

.contact-desc {
    color: white !important;
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    display: inline-block !important;
    padding: 1rem !important;
    padding-bottom: 20px !important;
}

.contact-desc span {
    font-weight: 600 !important;
}

.contact-btn {
    display: inline-block !important;
}

.contact-detail {
    margin: 1rem !important;
}

.contact-detail-card {
    border: 1px solid var(--dark) !important;
    border-radius: 20px 0 20px 0 !important;
    margin: 1rem 0 !important;
    padding: 20px !important;
}

.detail-icon {
    font-size: 2rem !important;
    font-weight: 600 !important;
}

@media screen and (max-width: 769px) {
    body {
        line-height: 1.6 !important;
    }

    .menu-list {
        display: none !important;
    }

    .nav-logo {
        justify-content: space-between !important;
        margin: 0 20px !important;
    }

    .menu-icon {
        display: block !important;
    }

    .menu-list {
        display: block !important;
        position: absolute !important;
        left: 0 !important;
        top: -1000px !important;
        transition: all .5s ease-in-out !important;
        background-color: white !important;
        z-index: 1;
    }

    .top {
        top: 170px !important;
    }

    .menu-items {
        margin-top: 20px !important;
    }

    .menu-item {
        display: block !important;
        text-align: center !important;
    }

    .about-content {
        grid-template-columns: 100% !important;
    }

    .detail-content {
        grid-template-columns: 100% !important;
    }

    .gallery-items {
        grid-template-columns: repeat(2, 50%) !important;
    }

    .about-section-content {
        display: block !important;
    }

    .section-image {
        width: 100% !important;
    }

    .section-content {
        width: 100% !important;
    }

    .cat-section-content {
        grid-template-columns: 100% !important;
    }

    .comment-section-container {
        grid-template-columns: 100% !important;
    }

    .product-card {
        display: block !important;
    }

    .product-image {
        width: 100% !important;
    }

    .product-details {
        width: 100% !important;
    }

    .detail-content {
        display: block !important;
    }

    .image {
        width: 100% !important;
    }

    .detail-text {
        width: 100% !important;
    }

    .contact-content {
        display: block !important;
        width: 100% !important;
    }

    .section-title {
        width: 50% !important;
    }

    .section-title {
        font-size: 1.5rem !important;
    }

    .slider-container {
        height: 50vh !important;
    }

    .slider-section {
        display: none !important;
    }

    .header {
        display: block !important;
    }

    .dropdown {
        display: block !important;
    }

    .dropdown-menu {
        /* display: none !important; */
        width: 100% !important;
        background: white !important;
        border-radius: 10px !important;
    }

    .dropdown i:hover .dropdown-menu {
        display: block !important;
    }

    .dropdown-menu .menu-item {
        display: block !important;
        text-align: center !important;
        /* padding: 10px !important; */
        border-bottom: 1px solid var(--dark);
        font-size: 1rem !important;
    }
}